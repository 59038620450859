import {
  createStore,
  applyMiddleware,
  combineReducers,
  Middleware,
  AnyAction
} from 'redux'
import { createMigrate, persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import reducers from './ducks'
import api from '../utils/api.js'
import apiAsync from '../utils/api-async'
import { migrations } from './migrations'
import { composeWithDevTools } from 'redux-devtools-extension'

const persistConfig = {
  key: 'root',
  storage,
  version: 0,
  blacklist: ['api'],
  migrate: createMigrate(migrations, { debug: false })
}

const setAcceptLanguageMiddleware = (apiAsync: any): Middleware => {
  return () => (next) => (action: AnyAction) => {
    if (action.type === 'i18n / changeLanguage') {
      apiAsync.setAcceptLanguage(action.lang)
    }
    return next(action)
  }
}

const configureStore = (initialState = {}) => {
  const rootReducer = combineReducers(reducers)

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const setAcceptLanguage = setAcceptLanguageMiddleware(apiAsync)

  const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(thunk.withExtraArgument(api), setAcceptLanguage)
    )
  )
  const persistor = persistStore(store)

  return { store, persistor }
}

export default configureStore

import authentication, { AuthenticationState } from '../../modules/login/ducks/authentication'
import api, { ApiState } from './api'
import masterdata, { MasterdataState } from '../../modules/masterdata/ducks'

export type ReduxState = {
  authentication: AuthenticationState,
  api: ApiState,
  masterdata: MasterdataState
}

const reducers = { authentication, api, masterdata }

export default reducers

import { Suspense } from 'react'
import { useTranslation } from 'react-i18n-lite'

import SuspenseFallback from './SuspenseFallback'
import useToken from './useToken'

const SystemConfig = ({ children }) => {
  const { t } = useTranslation()

  useToken()

  return (
    <Suspense fallback={<SuspenseFallback message={t('system.config')} />}>
      {children}
    </Suspense>
  )
}

export default SystemConfig

import apiAsync from './api-async'

class Api {
  postLogin (obj, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'login',
      method: 'POST',
      obj: obj,
      showLoading: showLoading
    })
  }

  postReloadToken (obj, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'reload-token',
      method: 'POST',
      obj: obj,
      showLoading: showLoading
    })
  }

  postChangeSelfPassword (obj, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'change-self-password',
      method: 'POST',
      obj: obj,
      showLoading: showLoading
    })
  }

  // postFilter(idObjectClass, query, showLoading){
  //   return apiAsync.requestSync({
  //     endpoint: 'filter',
  //     obj: {
  //       "query": query,
  //       "orderby": {},
  //       "objectclass": idObjectClass,
  //       "pagination":{
  //           "offset":0,
  //           "limit":10
  //       },
  //       "visualization":"classic-list"
  //     },
  //     method: 'POST',
  //     showLoading: showLoading
  //   })
  // }

  postFilter (idObjectClass, query, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'filter',
      method: 'GET',
      showLoading: showLoading
    })
  }

  getFilterFields (showLoading) {
    return apiAsync.requestSync({
      endpoint: 'filter_fields',
      method: 'GET',
      showLoading: showLoading
    })
  }

  getList ({ idObjectClass, query, showLoading = false, limit, offset, orderby = [] }) {
    return apiAsync.requestSync({
      endpoint: 'objects_filter',
      obj: {
        query: query,
        orderby: orderby,
        objectclass: idObjectClass,
        pagination: {
          offset: offset,
          limit: limit
        },
        visualization: 'classic-list'
      },
      method: 'POST',
      showLoading: showLoading
    })
  }

  /*
  getAutocomplete (value, endpoint, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'objects_filter',
      method: 'post',
      showLoading: showLoading
    })
  }
  */

  postObjectsFilter (obj, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'objects_filter',
      method: 'post',
      obj: obj,
      showLoading: showLoading
    })
  }

  getObjectClassFields (uuid, showLoading = false) {
    return apiAsync.requestSync({
      endpoint: 'object_classe_uuid',
      path: uuid,
      method: 'GET',
      showLoading: showLoading,
      customEndpoint: false
    })
  }

  getObjectclasses (showLoading) {
    return apiAsync.requestSync({
      endpoint: 'object_classes',
      method: 'GET',
      showLoading: showLoading,
      customEndpoint: false
    })
  }

  saveObjects (obj, uuid, showLoading) {
    if (uuid) {
      return apiAsync.requestSync({
        endpoint: 'objects',
        method: 'PATCH',
        path: '/' + uuid,
        obj: obj,
        showLoading: showLoading
      })
    }
    return apiAsync.requestSync({
      endpoint: 'objects',
      method: 'POST',
      obj: obj,
      showLoading: showLoading
    })
  }

  getFilteractions (objectClass, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'objects_filter',
      method: 'POST',
      obj: {
        query: {
          'situation.des': {
            $eq: '40'
          },
          'filter_action_objectclass.uuid': {
            $in: [objectClass]
          }
        },
        objectclass: window.idObjectclassFilteraction,
        orderby: [],
        pagination: {
          offset: 0,
          limit: 10
        },
        visualization: 'classic-list'
      },
      showLoading: showLoading
    })
  }

  getError500Family (showLoading) {
    return apiAsync.requestSync({
      endpoint: 'random_status_code',
      method: 'GET',
      showLoading: showLoading,
      customEndpoint: false
    })
  }

  getDataGroups (idClassifiedObjectClass, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'objects_filter',
      method: 'POST',
      obj: {
        query: {
          'situation.des': {
            $eq: '40'
          },
          'objectclass_which_fields_are_classified.uuid': {
            $in: [idClassifiedObjectClass]
          }
        },
        objectclass: window.idObjectClass,
        orderby: [],
        pagination: {
          offset: 0,
          limit: 10
        }
      },
      showLoading: showLoading
    })
  }
}

export default (new Api())

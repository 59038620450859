import { toast } from 'react-toastify'
import * as systemConstants from '../../modules/shared/Constants'
import Objectclass, { RelationClass } from 'shared/types/objectclass.type'

export function copyToClipboard (text: string) {
  const elementTemporary = document.createElement('textarea')
  document.body.appendChild(elementTemporary)
  elementTemporary.value = text
  elementTemporary.select()
  document.execCommand('copy')
  document.body.removeChild(elementTemporary)
  toast(systemConstants.STR_COPY_TO_CLIPBOARD, {
    autoClose: 2000,
    type: toast.TYPE.SUCCESS
  })
}

export const setRelationsIds = ({ fields, relation_classes: relationClasses }: Objectclass) => {
  Object.values(fields)
    .filter(({ type }) => type === 'RELATION')
    .forEach((field) => {
      const relationClass = relationClasses?.[field.column_json] || {} as RelationClass
      field.relation_objectclass_uuid = relationClass.uuid_objectclass
    })
}

import { Suspense, lazy } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18n-lite'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer, toast } from 'react-toastify'

import 'asset/css/fonts.css'
import 'asset/css/default.css'
import 'asset/css/toastify.css'

import SuspenseFallback from 'modules/shared/SuspenseFallback'
import NavigationBar from 'modules/shared/NavigationBar'
import * as constants from 'modules/shared/Constants'
import { useHandleLanguage } from 'hooks'
import { ReduxState } from 'state/ducks'

// code-splitting
const Loading = lazy(() => import('modules/shared/Loading'))
const AuthenticationRenewal = lazy(() =>
  import('modules/shared/AuthenticationRenewal')
)
const RedirectToLogin = lazy(() => import('modules/shared/RedirectToLogin'))
const Router = lazy(() => import('modules/shared/Router'))
const NetworkDetector = lazy(() => import('modules/shared/NetworkDetector'))
const EnvironmentWarning = lazy(() => import('modules/shared/EnvironmentWarning'))
const UseDocumentTitle = lazy(() => import('modules/shared/UseDocumentTitle'))
const DefaultTemplate = lazy(() => import('modules/shared/DefaultTemplate'))

const ServiceWorkerWrapper = lazy(() =>
  import('modules/shared/ServiceWorkerBox/ServiceWorkerWrapper')
)

const Application = () => {
  const { t } = useTranslation()

  const isLoading = useSelector<ReduxState>(({ api: { isPending } }) => isPending)

  useHandleLanguage()

  return (
    <Suspense fallback={<SuspenseFallback message={t('hello-yes.loading')} />}>
      <Loading active={isLoading} />
      <Suspense
        fallback={<SuspenseFallback message={t('hello-yes.authentication-renewal')} />}
      >
        <AuthenticationRenewal />
      </Suspense>

      <Suspense fallback={<SuspenseFallback message={t('hello-yes.redirect-to-login')} />}>
        <RedirectToLogin />
      </Suspense>

      <Suspense fallback={<SuspenseFallback message='Loading service worker...' />}>
        <ServiceWorkerWrapper />
      </Suspense>

      <Suspense
        fallback={
          <SuspenseFallback message={t('hello-yes.loading-environment-warning')} />
        }
      >
        <EnvironmentWarning />
        <NavigationBar />
      </Suspense>

      <Suspense
        fallback={
          <SuspenseFallback message={t('hello-yes.loading-network-detector')} />
        }
      >
        <NetworkDetector />
      </Suspense>

      <Suspense fallback={<p>Loading default template...</p>}>
        <DefaultTemplate>
          <Suspense fallback={<SuspenseFallback message={t('hello-yes.loading-router')} />}>
            <Router />
          </Suspense>
        </DefaultTemplate>
      </Suspense>

      <Suspense fallback={<p>Update page title...</p>}>
        <UseDocumentTitle title={constants.STR_SYSTEM_TITLE} />
      </Suspense>

      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
    </Suspense>
  )
}

export default Application

import enUS from './en-US.json'
import ptBR from './pt-BR.json'
import deDE from './de-DE.json'

export const defaultLanguage = 'en-US'

const locales = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'de-DE': deDE
}

export default locales

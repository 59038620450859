enum AuthenticationActionTypes {
  LOGIN_SUCCEEDED = 'login/SUCCEEDED',
  LOGIN_FAILED = 'login/FAILED',
  TOKEN_EXPIRED = 'login/TOKEN_EXPIRED',
  LOGOUT = 'login/LOGOUT'
}

type AuthenticationResponseData = {
  expiration_date: string,
  token: string,
}

export type AuthenticationState = {
  username?: string,
  expiration_date?: string,
  token?: string,
}

/**
 * Thunk action creators
 */
export const loginSucceeded = (email: string, data: AuthenticationResponseData) => {
  return {
    type: 'login/SUCCEEDED',
    data: {
      username: email,
      expiration_date: data.expiration_date,
      token: data.token
    }
  }
}

export const loginFailed = () => {
  return {
    type: 'login/FAILED'
  }
}

type AuthenticationAction = {
  type: AuthenticationActionTypes,
  data?: AuthenticationState
}

/**
 * Reducer
 */
export default function authentication (state = {}, action: AuthenticationAction): AuthenticationState {
  switch (action.type) {
    case 'login/SUCCEEDED':
      return action.data || {}
    case 'login/FAILED':
      return {}
    case 'login/TOKEN_EXPIRED':
      return {}
    case 'login/LOGOUT':
      return {}
    default:
      return state
  }
}

import Objectclass, { Fields } from 'shared/types/objectclass.type'
import api from '../../../utils/api'
import { setRelationsIds } from '../../../utils/helpers'
import { hasData } from '../../../utils/helpers/response'
import { Dispatch } from 'redux'

export enum ActionTypes {
  Request = 'FilterFields / Request',
  Receive = 'FilterFields / Receive',
  Error = 'FilterFields / Error'
}

export type FilterFieldsState = {
  isFetching: boolean,
  items?: Fields,
  error: string,
  all?: Objectclass,
  lastUpdated?: number
}

export type FilterFieldsAction = {
  type: ActionTypes,
  response?: Objectclass,
  receivedAt?: number,
  error?: string
}

const requestFilterFields = (): FilterFieldsAction => ({
  type: ActionTypes.Request
})

const receiveFilterFields = (response: Objectclass): FilterFieldsAction => ({
  type: ActionTypes.Receive,
  response,
  receivedAt: Date.now()
})

const errorFilterFields = (error?: string): FilterFieldsAction => ({
  type: ActionTypes.Error,
  error,
  receivedAt: Date.now()
})

const formatDisplayText = ({ fields }: { fields: Fields }) => (
  Object.values(fields).forEach((field) => {
    if (typeof field.display_text === 'string') {
      field.display_text = { des: field.display_text }
    }
  })
)

const formatResponse = ({ data: [objectClassData] }: { data: [Objectclass] }) => {
  setRelationsIds(objectClassData)
  formatDisplayText(objectClassData)
}

export const getFilterFields = (idObjectClass: string) => (dispatch: Dispatch<FilterFieldsAction>) => {
  dispatch(requestFilterFields())
  api.getObjectClassFields(idObjectClass, false)
    .then((response) => {
      if (hasData(response) && response.data[0].fields) {
        formatResponse(response)
        dispatch(receiveFilterFields(response.data[0]))
      } else {
        dispatch(errorFilterFields())
      }
    })
    .catch((error) => {
      dispatch(errorFilterFields(error.message))
    })
}

const defaultState: FilterFieldsState = {
  isFetching: false,
  items: undefined,
  error: '',
  all: undefined,
  lastUpdated: undefined
}

export default function filterFields (state = defaultState, action: FilterFieldsAction): FilterFieldsState {
  switch (action.type) {
    case ActionTypes.Request:
      return { ...state, isFetching: true, error: '' }
    case ActionTypes.Receive:
      return {
        ...state,
        isFetching: false,
        items: action.response?.fields,
        lastUpdated: action.receivedAt,
        error: '',
        all: action.response
      }
    case ActionTypes.Error:
      return {
        isFetching: false,
        items: {},
        error: action.error || '',
        lastUpdated: action.receivedAt,
        all: undefined
      }
    default:
      return state
  }
}

import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from '@yes.technology/react-toolkit'
import 'moment/locale/pt-br'
import 'moment/locale/de'

import configureStore from './state/store'
import Application from './modules/application/containers/Application'
import apiAsync from './utils/api-async'
import { configStatus } from './utils/configStatus'
import SystemConfig from './modules/shared/SystemConfig'
import ResizeDetector from 'modules/shared/ResizeDetector'
import locales, { defaultLanguage } from 'modules/shared/I18n'
import { TranslationContainer } from 'react-i18n-lite'

// initial state
const reduxStoreAndPersistor = configureStore(window.REDUX_INITIAL_DATA)

configStatus(window)
apiAsync.setConfig(window.msApi)
apiAsync.setDispatch(reduxStoreAndPersistor.store.dispatch)

// garbage-collected
delete window.REDUX_INITIAL_DATA

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ThemeProvider theme={defaultTheme}>
    <ReduxProvider store={reduxStoreAndPersistor.store}>
      <PersistGate loading={null} persistor={reduxStoreAndPersistor.persistor}>
        <BrowserRouter>
          <TranslationContainer defaultLanguage={defaultLanguage} locales={locales}>
            <SystemConfig>
              <ResizeDetector>
                <Application />
              </ResizeDetector>
            </SystemConfig>
          </TranslationContainer>
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
  </ThemeProvider>
)

import { Link, useLocation, useNavigate, useNavigationType } from 'react-router-dom'

import { useTranslation } from 'react-i18n-lite'
import { isEmbedded } from '../../../utils/helpers/iframe'

export default function NavigationBar () {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const navigationType = useNavigationType()
  const { pathname } = useLocation()

  const goBack = () => {
    navigate(-1)
  }

  const isInHome = pathname === '/'
  const hasPreviousPage = navigationType !== 'POP'

  return (
    <div>
      {!isEmbedded() && pathname !== '/login' && (
        <div className='alert alert-secondary rounded-0 mb-4 text-center'>
          <button
            className='btn btn-light btn-sm ml-3'
            onClick={goBack}
            disabled={isInHome && !hasPreviousPage}
          >
            {t('navigation.back')}
          </button>

          <Link
            to='/'
            className='btn btn-light btn-sm ml-3'
            disabled={isInHome}
          >
            {t('navigation.home')}
          </Link>
        </div>
      )}
    </div>
  )
}

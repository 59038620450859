/* Form Validation */
export const MINIMUM_CHARACTER = 'Mínimo de caracteres'
export const MAXIMUM_CHARACTER = 'Máximo de caracteres'
export const REQUIRED = 'Campo obrigatório'
export const REQUIRED_CONDITIONALLY = 'Ao menos um campo obrigatório'

export const RESPONSE_MESSAGE_DEFAULT = 'Não foi possível executar esse comando.'
export const STR_COPY_TO_CLIPBOARD = 'Copiado!'
export const STR_SYSTEM_TITLE = 'Masterdata'

/* Authentication renewal - token expiration */
export const SYNCHRONIZATION_ERROR_CORRECTION = 300
export const SHOW_ALERT_FROM = 300

import { combineReducers } from 'redux'

import filterFields, { FilterFieldsState, getFilterFields } from './filterFields'

export type MasterdataState = { filterFields: FilterFieldsState }

const masterdata = combineReducers<MasterdataState>({ filterFields })

export const masterdataOperations = { getFilterFields }

export default masterdata

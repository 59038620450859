import PropTypes from 'prop-types'

export function SuspenseFallback ({ message, className }) {
  return (
    <div>
      <div className={className}>{message}</div>
    </div>
  )
}

SuspenseFallback.defaultProps = {
  message: '',
  className: ''
}

SuspenseFallback.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string
}

export default SuspenseFallback

const getMissingKeys = (config, requiredKeys) => (
  requiredKeys.filter(key => !Object.keys(config).includes(key))
)

export const configStatus = (config) => {
  const missingVariables = getMissingKeys(config, requiredVariables)
  const missingUrls = getMissingKeys(config.msApi, requiredUrls)

  if (missingVariables.length > 0 || missingUrls.length > 0) {
    window.alert('Environment variables not configured')
  }

  missingVariables.forEach(variable => {
    console.error('Variable not configured in environment: ' + variable)
  })

  missingUrls.forEach(variable => {
    console.error('URL not configured in environment: ' + variable)
  })
}

const requiredVariables = [
  'msApi',
  'waEnv',
  'idClassificationNavigation',
  'idObjectClass',
  'idObjectclassFilteraction',
  'uuidObjectclassField',
  'apiRetry'
]

const requiredUrls = [
  'login',
  'reload-token',
  'change-self-password',
  'list',
  'filter',
  'filter_fields',
  'status',
  'situation',
  'object_classes',
  'object_classe_uuid',
  'objects_filter',
  'objects'
]

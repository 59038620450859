export type ApiState = { requests: string[]; isPending: boolean }

type ApiAction = { type: string; data?: { requestUuid: string } }

const defaultState: ApiState = {
  requests: [],
  isPending: false
}

export default function api (state = defaultState, action: ApiAction) {
  switch (action.type) {
    case 'api/REQUESTED': {
      return {
        ...state,
        requests: [...state.requests, action.data?.requestUuid],
        isPending: true
      }
    }
    case 'api/RECEIVED': {
      const requests = state.requests.filter((i) => {
        return i !== action.data?.requestUuid
      })
      return {
        ...state,
        requests: requests,
        isPending: requests.length > 0
      }
    }
    case 'login/TOKEN_EXPIRED':
      return {
        requests: [],
        isPending: false
      }
    default:
      return state
  }
}

import jwtDecode from 'jwt-decode'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { loginSucceeded } from '../../login/ducks/authentication'
import apiAsync from '../../../utils/api-async'

const useToken = () => {
  const dispatch = useDispatch()

  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('token') || ''

  useEffect(() => {
    const validateAndSaveToken = () => {
      if (!token) return
      try {
        const decodedHeader = jwtDecode(token, { header: true })
        if (decodedHeader?.typ !== 'JWT' || decodedHeader?.alg !== 'HS256') {
          throw new Error('Invalid token')
        }

        apiAsync.setAuthToken(token)

        const decoded = jwtDecode(token)
        dispatch(
          loginSucceeded(decoded.email, {
            token,
            expiration_date: decoded.expiration_date
          })
        )
        searchParams.delete('token')
        setSearchParams(searchParams)
      } catch (error) {
        console.log(error.message)
      }
    }
    validateAndSaveToken()
  }, [dispatch, setSearchParams, searchParams, token])

  return token
}

export default useToken
